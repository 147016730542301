@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

*{
  @apply m-0 p-0 box-border font-sans-serif text-2xl
}
html{
  @apply h-full text-[62.5%]
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font 'DM Sans', sans-serif; */
}

.accent{
  background: linear-gradient(27deg, #FFC593 0%, #BC7198 49%, #5A77FF 95%);
}
.switch{
  @apply relative inline-block w-[6.4rem] h-[3.2rem]
}
.switch input{
  @apply opacity-0 h-0 w-0
}
.slider{
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray;
  -webkit-transition:.4s;
  -moz-transition: .4s;
  transition:.4s;

}
.slider::before{
  content: "";
  position:absolute;
  height: 2.4rem;
  width: 2.4rem;
  left:4px;
  bottom:4px;
  background-color: black;
  -webkit-transition:.4s;
  -moz-transition:.4s;
  transition:.4s;
}

input:checked + .slider{
  @apply bg-black;
}
input:checked + .slider::before{
  @apply bg-white;
}
/* input:focus + .slider {
  box-shadow: 0 0 1px #f32121;
} */
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}